import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { allMesages, deleteMessageById } from '../../actions/messages.actions';
import MessagePreview from '../_ui/MessagePreview/MessagePreview';
import SectionTitle from '../_ui/SectionTitle/SectionTitle';

const MessagesDashboard = () => {
    const [messages, setMessages] = useState([]);

    const getMessages = useCallback(async () => {
        let getData = await allMesages();
        setMessages(getData.reverse());
    }, []);

    useEffect(() => {
        getMessages();
    }, [getMessages]);

    const deleteMessage = async (id) => {
        await deleteMessageById(id);
        await getMessages();
    };

    const askIfDelete = (id) => {
        Swal.fire({
            title: '¿Seguro?',
            text: 'Si borras esto, el mensaje desaparece',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Borrar Mensaje!',
            cancelButtonText: '¡Uy, no!',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMessage(id);
                Swal.fire('¡Mensaje borrado!', 'Este mensaje se marchó para no volver', 'success');
            }
        });
    };

    return (
        <section id="messages-dashboard">
            <SectionTitle
                image={ process.env.REACT_APP_MSG_IMAGE }
                title="Mensajes"
                instructions="Aquí verás la relación de mensajes recibidos desde la web"
            />
            <section className="messages">
                { messages?.length > 0 && (
                    messages.map(msg => (
                        <MessagePreview key={ msg._id } message={ msg } askIfDelete={ askIfDelete } />
                    ))
                ) }
            </section>

        </section>
    );
};

export default MessagesDashboard;
