import React from 'react';
import Swal from 'sweetalert2';
import { startSignup } from '../../../actions/auth.action';
import useForm from '../../../hooks/useForm';

const SignupScreen = ({ setShow }) => {
	const { values, handleInputChange } = useForm({});
	const { name, email, password, password2 } = values;

	const handleSignup = async (e) => {
		e.preventDefault();
		if (password === password2) {
			const signedupUser = await startSignup(name, email, password);
			signedupUser && Swal.fire('¡Bien!', 'Usuario creado', 'success');
			setShow(false);
		} else {
			Swal.fire('Error', 'Las contraseñas deben coincidir', 'error');
		}
	};

	return (
		<>
			<section className='login'>
				<form onSubmit={ handleSignup }>
					<label>Nombre</label>
					<input type='text' onChange={ handleInputChange } placeholder='Tu nombre' name='name' />
					<label>Email</label>
					<input type='email' onChange={ handleInputChange } placeholder='Tu email' name='email' />
					<label>Contraseña</label>
					<input type='password' onChange={ handleInputChange } placeholder='Contraseña' name='password' />
					<label>Repite Contraseña</label>
					<input type='password' onChange={ handleInputChange } placeholder='Repite Contraseña' name='password2' />
					<button type='submit' className='my-btn mini right'>
						Registro
					</button>
				</form>
			</section>
		</>
	);
};

export default SignupScreen;
