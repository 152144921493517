import React from 'react';

const PostLanguages = ({ setSelectedPost, selectedPost }) => {

    const addLanguage = ({ target }) => {
        let lang = target.value;
        saveLanguage(lang);
    };

    const saveLanguage = (lang) => {
        setSelectedPost(prevState => ({ ...prevState, language: lang }));
    };

    return (
        <>
            <label>Idioma de la variante</label>
            <select name='category' onChange={ addLanguage } value={ selectedPost.language }>
                <option value='ES'> Español</option>
                <option value='EN'> Inglés </option>
            </select>

        </>
    );
};

export default PostLanguages;