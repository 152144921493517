import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MessagePreview = ({ askIfDelete, message }) => {
    let history = useHistory();
    const nextPath = (path) => {
        history.push(path);
    };

    return (
        <>
            <article className='each-post-prev'>
                <div className='info'>
                    <div className="title-btn">
                        <h5>{ message.subject }</h5>

                    </div>
                    <p>Fecha de recepción: <strong>{ dayjs(message?.createdAt).format('DD/MM/YYYY HH:mm') }</strong></p>
                    <button className="my-btn mini extra-btn" onClick={ () => nextPath(`/h7/mensajes/ver/${message._id}`) }>Ver mensaje</button>
                </div>
            </article>
        </>
    );
};

export default MessagePreview;
