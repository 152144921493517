import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ActionButton from '../_ui/ActionButton/ActionButton';

export const EditGroupSection = React.memo(({ nameValue, deleteField, inputType, editLabel, editAction, editValue, submitEdit, imageEdit = false }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			{ editValue ? (
				<div className='edit-group'>
					{ imageEdit ? (
						<div>
							<div className="title-btn">
								<label>{ editLabel }: </label>
								<div className='btn-group'>
									<ActionButton type={ 'delete' } action={ () => deleteField(nameValue) } />
									<ActionButton type={ 'edit' } action={ handleShow } />
								</div>
							</div>
							<figure>
								<img src={ editValue } alt={ nameValue } />
							</figure>
						</div>
					) : (
						<div>
							<div className="title-btn">
								<label>{ editLabel }: </label>
								<div className='btn-group'>
									<ActionButton type={ 'delete' } action={ () => deleteField(nameValue) } />
									<ActionButton type={ 'edit' } action={ handleShow } />
								</div>
							</div>
							<p>{ editValue }</p>
						</div>
					) }
				</div>
			) : (
				<div className='edit-group'>
					<div className="title-btn">
						<label>
							{ editLabel }
						</label>
						<ActionButton type={ 'add' } action={ handleShow } />
					</div>
					<small>Sin datos</small>
				</div>
			) }

			<Modal show={ show } onHide={ handleClose }>
				<Modal.Header closeButton>
					<h4>{ editLabel }: { imageEdit ? '' : editValue }</h4>
				</Modal.Header>
				<Modal.Body>
					<form className='edit-form' onSubmit={ submitEdit }>
						<input name={ nameValue } type={ inputType } onChange={ editAction } placeholder={ editValue } />
						<button className='my-btn mini right' type='submit' onClick={ handleClose }>
							Guardar
						</button>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
});
