import Swal from 'sweetalert2';
import { fetchConToken } from '../helpers/fetch';

export const allMesages = async () => {
	const resp = await fetchConToken(`messages`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		const messages = body.messages;
		return messages;
	} else {
		Swal.fire('Error', body.err, 'error');
	}
};

export const getMessageById = async (id) => {
	const resp = await fetchConToken(`messages/message/${id}`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		const message = body.message;
		return message;
	} else {
		Swal.fire('Error', body.err, 'error');
	}
};

export const deleteMessageById = async (id) => {
	const resp = await fetchConToken(`messages/${id}`, {}, 'DELETE');
	const body = await resp.json();
	if (body.ok) {
		Swal.fire('¡Mensaje Borrado!', body.message, 'succes');
	} else {
		Swal.fire('Error al borrar', body.err, 'error');
	}
};
