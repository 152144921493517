import React, { useEffect, useState } from 'react';
//Hooks
import { useParams } from 'react-router-dom';

//Componentes
import PostMethods from '../PostMethods/PostMethods';
import SectionTitle from '../../_ui/SectionTitle/SectionTitle';

import { getThisPost } from '../../../actions/posts.action';

const AddPostScreen = () => {
    let params = useParams();

    const [selectedPost, setSelectedPost] = useState({ title: 'Sin título', subtitle: '', language: 'ES', slugArray: [], content: { slider: [], image: [], text: [], video: [], gallery: [] } });

    useEffect(() => {
        findCurrentPost(params.postID);
    }, [params.postID]);

    //Post Methods
    const findCurrentPost = async (id) => {
        const currentPost = await getThisPost(id);
        setSelectedPost(currentPost);
    };

    return (
        <section id="add-posts-screen">
            <SectionTitle
                image={ process.env.REACT_APP_POST_IMAGE }
                title="Nueva variante"
                instructions="Estás añadiendo una variante nueva :)"
            />

            <PostMethods selectedPost={ selectedPost } setSelectedPost={ setSelectedPost } />
        </section>

    );
};

export default AddPostScreen;
