import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import { deleteMessageById, getMessageById } from '../../../actions/messages.actions';
import SectionTitle from '../../_ui/SectionTitle/SectionTitle';

const Message = () => {
    let params = useParams();
    let history = useHistory();

    const [message, setMessage] = useState();

    const findCurrentMessage = useCallback(async (id) => {
        let getData = await getMessageById(id);
        setMessage(getData);
    }, []);

    useEffect(() => {
        findCurrentMessage(params.msgID);
    }, [findCurrentMessage, params.msgID]);



    const askIfDelete = () => {
        Swal.fire({
            title: '¿Seguro?',
            text: 'Si borras esto, el mensaje desaparece',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Borrar Mensaje!',
            cancelButtonText: '¡Uy, no!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteMessageById(params.msgID);
                history.goBack();
                Swal.fire('¡Mensaje borrado!', 'Este mensaje se marchó para no volver', 'success');
            }
        });
    };


    return (
        <section id="message-screen">
            <SectionTitle
                image={ process.env.REACT_APP_MSG_IMAGE }
                title={ message?.subject }
                instructions={ `Mensaje enviado en la web por ${message?.name}` }
            />
            <article className="post-btn-group btn-group">
                <button className="my-btn mini secondary" onClick={ () => history.goBack() }>Volver</button>
                <button className="my-btn mini danger" onClick={ () => askIfDelete() }>Borrar Mensaje</button>
            </article>
            <section className="message-content">
                <div className="user-info">
                    <p><strong>Persona interesada:</strong> { message?.name } { message?.lastName }</p>
                    <p><strong>Teléfono de contacto:</strong> { message?.phone }</p>
                    <p><strong>Email de contacto:</strong> { message?.email }</p>
                    { message?.company && <p><strong>Empresa:</strong> { message?.company }</p> }
                    {
                        message?.file && (
                            <button className="my-btn mini third" onClick={ () => window.open(message?.file) }>Ver documento adjunto</button>
                        )
                    }
                </div>
                <p><strong>Mensaje:</strong></p>
                <p className="message">{ message?.message }</p>
                <a className="my-btn mini" href={ `mailto:${message?.email}` }>Responder Mensaje</a>
            </section>


        </section>
    );
};

export default Message;
