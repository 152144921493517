import React from 'react';
import SectionTitle from '../_ui/SectionTitle/SectionTitle';

const DashboardScreen = () => {
	return (
		<section id="dashboard-screen">
			<SectionTitle
				image={ process.env.REACT_APP_MAIN_IMAGE }
				title="Dashboard"
				instructions="Éste es el dashboard de gestión de contenido de tu web :)"
			/>
		</section>
	);
};

export default DashboardScreen;
